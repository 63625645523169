import { LeftArrow, RightArrow } from 'assets/icons/icon';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import ballPool from 'assets/images/ballpoolhome.png';
import CashyCarrom from 'assets/images/cashycarromhome.png';
import LudoImage from 'assets/images/ludohome.png';
import Rummy from 'assets/images/rummy/rummy.png';

const TopGames = () => {
	const WINDOW_WIDTH = window.innerWidth;
	const navigate = useNavigate();
	const location = useLocation();

	const LeftSideArrow = ({ ...props }: any) => {
		const { className } = props;
		return (
			<div className={className} {...props}>
				<LeftArrow className="arrow pr-25 cursor-pointer" />
			</div>
		);
	};

	const RightSideArrow = ({ ...props }: any) => {
		const { className } = props;
		return (
			<div className={className} {...props}>
				<RightArrow className="arrow pr-25 cursor-pointer" />
			</div>
		);
	};

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		cssEase: 'linear',
		centerPadding: '20px',
		prevArrow: <LeftSideArrow />,
		nextArrow: <RightSideArrow />,
		autoplay: true,
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					variableWidth: true,
					centerMode: true,
					initialSlide: 2,
					centerPadding: '125px'
				}
			},
			{
				breakpoint: 450,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					variableWidth: true,
					centerMode: true,
					initialSlide: 1,
					centerPadding: '60px'
				}
			},
			{
				breakpoint: 400,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					variableWidth: true,
					centerMode: true,
					initialSlide: 1,
					centerPadding: '40px'
				}
			},
			{
				breakpoint: 350,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					variableWidth: true,
					centerMode: true,
					initialSlide: 1,
					centerPadding: '10px'
				}
			}
		]
	};

	const MostLikeGames: React.FC<{ pathname: string; image: string }> = ({ pathname, image }) => {
		return (
			<div
				onClick={() => {
					navigate(pathname);
				}}
				className="hover-flip1 position-relative"
			>
				<div className="hover_flip_wrapper">
					<img src={image} alt="game-thumbnail" className="demo-image cursor-pointer" />
				</div>
			</div>
		);
	};

	const renderMostLikeGames = () => {
		const games = [
			{ pathname: '/rummy', image: Rummy },
			{ pathname: '/8ball-pool', image: ballPool },
			{ pathname: '/cashy-carrom', image: CashyCarrom },
			// { pathname: '/tic-tac-toe', image: TicTacToe },
			{ pathname: '/ludo-race', image: LudoImage }
		];

		return games.map(
			(game, index) => location.pathname !== game.pathname && <MostLikeGames key={index} pathname={game.pathname} image={game.image} />
		);
	};

	return (
		<div className="upcomming-section top-game-section">
			<div className="upcoming--title d-flex justify-content-space-between justify-content--center align-items-center mb-50">
				<h1
					className="font-size-45 font-weight--800 line-height-53 no-margin text-transform--uppercase 
					font-style-italic position--absolute background-text letter-spacing"
				>
					MOST-LIKED GAMES
				</h1>
				<h1
					className="font-size-40 font-weight--800 line-height-53 no-margin text-transform--uppercase 
						font-style-italic position--relative top-game-text top--9 text-align--center letter-spacing"
				>
					MOST-LIKED GAMES
				</h1>
			</div>
			<div className="top-game-home-margin">
				{WINDOW_WIDTH > 768 ? (
					<div className="top-game-wrapper d-flex flex-wrap--wrap justify-content--center">{renderMostLikeGames()}</div>
				) : (
					<Slider {...settings} className="top-game-slider">
						{renderMostLikeGames()}
					</Slider>
				)}
			</div>
		</div>
	);
};

export default TopGames;

import { isOS } from 'shared/constants/constants';

import { AndroidIcon, AppleSvg, ScrollDownload } from 'assets/icons/icon';
import DownloadButton from 'shared/components/downloadButton/downloadButton';
import { handleDownload } from 'shared/utils';
import MobileBottomButton from './mobileBottomButton';

const TopBannerDownloadButtons: React.FC = () => {
	const WINDOW_WIDTH = window.innerWidth;

	return (
		<>
			{WINDOW_WIDTH > 768 && <DownloadButton />}
			{WINDOW_WIDTH <= 768 && (
				<>
					<div className="download-now-btn-wrapper width-100">
						<div className=" width-100">
							<a
								download={true}
								className=" text--decoration-none top-banner-btn btn font-weight--800 font-size-18 line-height-30 align-items-start scroll-btn width-100"
								onClick={() => handleDownload()}
							>
								{isOS ? (
									<AppleSvg className="" fill="white" width="25" height="25" />
								) : (
									<AndroidIcon className="" fill="white" width="25" height="25" />
								)}
								<span className="text-transform--uppercase mr-20 ml-20">Download Now</span>
								<ScrollDownload />
							</a>
						</div>
					</div>
					<MobileBottomButton />
				</>
			)}
		</>
	);
};

export default TopBannerDownloadButtons;

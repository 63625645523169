export const DOWNLOAD_URL = 'https://citta-games.sgp1.digitaloceanspaces.com/CittaGames.apk';
export const DOWNLOAD_IOS_URL = 'https://apps.apple.com/us/app/cittagames/id6444856152';
export const WINDOW_WIDTH = window.innerWidth;

export const isAndroid = /Android/.test(navigator.userAgent);

export const isOS =
	['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
	(navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const FREE_PRIVACY_POLICY = '/free/privacy-policy';

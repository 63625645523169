import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

export const useScrollTo = () => {
	const location = useLocation();
	useEffect(() => {
		scroll.scrollToTop({
			duration: 500,
			smooth: 'smooth'
		});
	}, [location.pathname]);
};

import { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from 'shared/components/footer/footer';
import Header from 'shared/components/header/header';
import { FREE_PRIVACY_POLICY } from 'shared/constants/constants';
import { useScrollTo } from 'shared/hooks/useScrollTop';

const Layout: React.FC<PropsWithChildren> = (props) => {
	const location = useLocation();
	const [scrollPosition, setScrollPosition] = useState(false);
	useScrollTo();

	useEffect(() => {
		const handleScroll = () => {
			const position = window.scrollY;
			if (position >= 50) {
				setScrollPosition(true);
			} else {
				setScrollPosition(false);
			}
		};
		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<div className="citta-wrapper">
			<div
				className={`header-container ${location.pathname === FREE_PRIVACY_POLICY ? 'position--relative' : ''} ${
					!scrollPosition ? 'header-container-background' : `is-scroll`
				} ${location.pathname === '/' ? 'home-header' : ''}`}
			>
				<div className="container">
					<Header />
				</div>
			</div>
			<div className={`${location.pathname.replace('/', '_')} main-container`}>
				<div className="container">
					<div className="p-t-b--20">{props.children}</div>
				</div>
			</div>
			<div className="footer-wrapper ">
				<div className="container">
					<Footer />
				</div>
			</div>
		</div>
	);
};

export default Layout;

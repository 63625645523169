import { useEffect, useState } from 'react';
import { isOS } from 'shared/constants/constants';

import { AndroidIcon, AppleSvg, ScrollDownload } from 'assets/icons/icon';
import { handleDownload } from 'shared/utils';

const MobileBottomButton: React.FC = () => {
	const WINDOW_WIDTH = window.innerWidth;
	const [headerButton, setHeaderButton] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const position = window.scrollY;
			if (position > 350 && WINDOW_WIDTH <= 768) {
				setHeaderButton(true);
			} else {
				setHeaderButton(false);
			}
		};
		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<>
			{WINDOW_WIDTH <= 768 && headerButton && (
				<div className={`width-100 header-btn-conatiner ${headerButton ? 'fade-in' : 'fade-out'}`}>
					<div className="btn-border mr-30 ml-30 width-100">
						<a
							download={true}
							className=" text--decoration-none hover-pulse btn font-weight--800 font-size-18 line-height-30 align-items-start scroll-btn width-100"
							onClick={() => handleDownload()}
						>
							{isOS ? (
								<AppleSvg className="mr-20" fill="white" width="30" height="30" />
							) : (
								<AndroidIcon className="mr-20" fill="white" width="30" height="30" />
							)}
							<span className="text-transform--uppercase mr-20"> Download Now </span>
							<ScrollDownload />
						</a>
					</div>
				</div>
			)}
		</>
	);
};

export default MobileBottomButton;

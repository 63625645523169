import { AndroidSvgIcon, AppleIcon } from 'assets/icons/icon';
import { handleDownload } from 'shared/utils';
interface Props {
	isHeaderSection?: boolean;
	className?: string;
	fill?: string;
	width?: string;
	height?: string;
}
const DownloadButton: React.FC<Props> = (props) => {
	const handleMouseEvent = () => {
		const buttons = document.querySelectorAll('.download-btn, .btn-posnawr');

		buttons.forEach((a) => {
			['mouseenter', 'mouseout'].forEach((evt) => {
				a.addEventListener(evt, (e: any) => {
					const parentOffset = a.getBoundingClientRect(),
						relX = e?.pageX - parentOffset.left,
						relY = 50;
					// relY = e?.pageY - parentOffset.top;
					const span = a.getElementsByTagName('span');
					span[0].style.top = relY + 'px';
					span[0].style.left = relX + 'px';
				});
			});
		});
	};
	return (
		<div className={`button-container ${props.isHeaderSection ? '' : 'mt-30'} `}>
			<div className={`button-wrapper d-flex align-items-center mt-12`} id="button">
				<a
					download={true}
					onClick={() => handleDownload(false)}
					className={`text--decoration-none btn ${
						props.className ? props.className : ''
					} font-weight--700 font-size-22 line-height-22 align-items-center justify-content-space-evenly download-btn ${
						props.className ? 'mr-15' : 'mr-28'
					}`}
					onMouseEnter={handleMouseEvent}
					onMouseLeave={handleMouseEvent}
				>
					<div className={`d-flex justify-content-space-evenly align-items-center ${props.className ? 'no-padding' : 'p-5'}`}>
						<AndroidSvgIcon width={props.width} height={props.height} />
					</div>
					<span />
				</a>
				<a
					className={`btn-posnawr ${
						props.className ? props.className : ''
					} d-flex justify-content-center align-items-center cursor-pointer`}
					onMouseEnter={handleMouseEvent}
					onMouseLeave={handleMouseEvent}
					onClick={() => handleDownload(true)}
				>
					<div className={`d-flex justify-content-space-evenly align-items-center ${props.className ? 'no-padding' : 'p-5'}`}>
						<AppleIcon fill="white" width={props.width} height={props.height} className="" />
					</div>
					<span />
				</a>
			</div>
		</div>
	);
};

export default DownloadButton;

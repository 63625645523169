import Banner from 'assets/images/rummy/rummy-home.png';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import BenefitsIcon from 'features/home/components/benefitsIcon';
import TopGames from 'features/home/components/topGames';
import AboutGame from 'shared/components/aboutGames/gameAbout';
import TopBannerDownloadButtons from 'shared/components/downloadButton/topBannerDownloadButtons';
import GameBanner from 'shared/components/gamesBanner/gameBanner';

interface Props {
	className?: any;
	style?: any;
	onClick?: any;
	type?: string;
	info?: string;
	id?: number;
	title?: string;
}

const Rummy: React.FC<Props> = () => {
	return (
		<div className="games-page-wrapper">
			<GameBanner imgUrl={Banner}>
				<div className="banner-contant d-flex flex-direction--column justify-content--center ">
					<h1 className="no-margin font-style--normal font-weight--700 font-size-50 line-height-68 text-transform--capitalize">
						Classic Rummy in
						<br />a Refreshed layout
					</h1>
					<small className="bonus-text font-size-20 font-weight--600  no-margin mt-100">Download & get upto ₹100 Welcome Bonus</small>
					<TopBannerDownloadButtons />
				</div>
			</GameBanner>

			<div className="games-page-wrapper-benefit-section">
				<BenefitsIcon />
			</div>

			<AboutGame
				title="About Rummy"
				info="Indian Rummy is a popular card game in India that is typically played with 2 to 6 players and follows specific rules rooted in traditional rummy gameplay. It is a skill-based game where players aim to arrange their cards into valid sets and sequences before their opponents."
			/>

			<div className="games-page-step-card bg-color-light-black border-radius-30  about-game">
				<div className="games-page-step-card--content">
					<h1 className="no-margin font-style--normal font-weight--700 font-size-30 line-height-32 step-card--title">
						Objective of the game
					</h1>
					<p className="grey-color font-size-18">
						The objective of Indian Rummy revolves around arranging the cards dealt into valid combinations of sequences and sets. A
						player must meet specific criteria to declare a win and complete the game successfully.
					</p>
					<p className="grey-color font-size-18">
						Primary goal is to organize all 13 cards in hand into valid combinations, by forming at least two sequences, out of which:
					</p>
					<p className="grey-color font-size-18">1. One must be a pure sequence (a sequence without the use of jokers).</p>
					<p className="grey-color font-size-18">2. The other sequence can be an impure sequence (can include jokers).</p>
					<p className="grey-color font-size-18">The remaining cards can be arranged into valid sets or sequences.</p>
				</div>
			</div>

			<div className="games-page-step-card bg-color-light-black border-radius-30  about-game">
				<div className="games-page-step-card--content">
					<h1 className="no-margin font-style--normal font-weight--700 font-size-30 line-height-32 step-card--title">Players and Decks:</h1>
					<p className="font-size-18">Players: 2 to 6 players.</p>
					<p className="font-size-18">Decks:</p>
					<p className="grey-color font-size-18 m-0">For 2 players: 1 standard deck (52 cards + 2 jokers).</p>
					<p className="grey-color font-size-18 m-0">For 3 to 6 players: 2 standard decks (104 cards + 4 jokers).</p>
					<p className="grey-color font-size-18">
						The game begins with one card drawn to form the open deck, while the remaining cards make up the closed deck. Additionally, a
						wild joker is selected randomly, and all cards of the same rank in any suit act as wild jokers, complementing the printed
						jokers in the deck. Players take turns drawing one card from either the open or closed deck and discarding one card to the
						open deck, aiming to improve their hand.
					</p>
					<p className="grey-color font-size-18">
						A valid declaration involves arranging all 13 cards into sequences and/or sets, with at least one pure sequence being
						mandatory. If a player declares a valid hand, they win the game. Scoring is based on the points of unarranged cards held by
						the losing players, with numbered cards valued at their face value, face cards (J, Q, K, A) at 10 points each, and jokers at
						zero points. The maximum penalty for an invalid hand or incorrect declaration is 80 points.
					</p>
					<p className="grey-color font-size-18">
						Rummy is highly based on skills as you have complete control over how to set your cards & which cards to pick/discard.
					</p>
				</div>
			</div>

			<div className="games-page-step-card bg-color-light-black border-radius-30  about-game">
				<div className="games-page-step-card--content">
					<h1 className="no-margin font-style--normal font-weight--700 font-size-30 line-height-32 step-card--title">Rummy Terms</h1>
					<p className="font-weight--700 font-size-18">Sequence:</p>
					<p className="m-0 grey-color font-size-18">
						A group of three or more consecutive cards of the same suit. Pure Sequence: A sequence without jokers (e.g., 5♥, 6♥, 7♥).
						Impure Sequence: A sequence that includes jokers (e.g., 8♦, 9♦, Joker).
					</p>

					<p className="font-weight--700 font-size-18 ">Set:</p>
					<p className="m-0 grey-color font-size-18 ">
						A group of three or four cards of the same rank but different suits (e.g., 4♠, 4♥, 4♦). Jokers can be used in sets.
					</p>

					<p className="font-weight--700 font-size-18 ">Joker:</p>
					<p className="m-0 grey-color font-size-18 ">
						Printed Joker: A special card included in the deck that acts as a wildcard. Wild Joker: A randomly selected card at the start
						of the game that can replace any card to form sets or sequences.
					</p>

					<p className="font-weight--700 font-size-18 ">Closed Deck:</p>
					<p className="m-0 grey-color font-size-18 ">
						The pile of cards placed face down from which players draw cards during their turn.
					</p>

					<p className="font-weight--700 font-size-18 ">Open Deck:</p>
					<p className="m-0 grey-color font-size-18 ">
						The pile of cards placed face up where players discard cards after their turn. Players can also pick from this pile.
					</p>

					<p className="font-weight--700 font-size-18 ">Draw:</p>
					<p className="m-0 grey-color font-size-18 ">Picking a card from either the closed deck or the open deck during a turn.</p>

					<p className="font-weight--700 font-size-18 ">Discard:</p>
					<p className="m-0 grey-color font-size-18 ">Placing an unwanted card into the open deck during your turn.</p>

					<p className="font-weight--700 font-size-18 ">Declaration:</p>
					<p className="m-0 grey-color font-size-18 ">
						Announcing that you have arranged all 13 cards into valid sequences and/or sets. A declaration must follow the game’s rules to
						be valid.
					</p>
				</div>
			</div>

			<div className="games-page-wrapper-top-game-section">
				<TopGames />
			</div>
		</div>
	);
};

export default Rummy;

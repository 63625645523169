import { PropsWithChildren } from "react";

interface IProps {
	imgUrl: string;
}

const GameBanner: React.FC<PropsWithChildren<IProps>> = (props) => {
	return (
		<div
			className={`top-banner-section d-flex ballpool-background  ${
				location.pathname === '/8ball-pool' ? 'position--relative container' : 'justify-content-space-between'
			}`}
		>
			{props.children}
			<div
				className={`${
					location.pathname === '/ludo-race'
						? 'banner-ludo-img'
						: location.pathname === '/tic-tac-toe' || location.pathname === '/cashy-carrom'
						? 'tictac-banner-img'
						: 'banner-image'
				}`}
			>
				<img src={props.imgUrl} alt="banner img" />
			</div>
		</div>
	);
};
export default GameBanner;

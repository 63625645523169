import { CustomerSupportIcon, DepositIcon, InMarketIcon, MadeInIndiaIcon, NoBotIcon, PlayIcon, SecureIcon, WithdrawalIcon } from 'assets/icons/icon';

const BenefitsIcon = () => {
	
	return (
		<div className="d-flex flex-direction--column  align-items-center text-align--center">
			<div className="benifit-icons-wrapper d-flex flex-wrap--wrap justify-content--center">
				<div className="icons-first-row">
					<NoBotIcon className="benifits-icons" fill="" />
					<PlayIcon className="benifits-icons" fill="" />
					<InMarketIcon className="benifits-icons" fill="" />
					<DepositIcon className="benifits-icons" fill="" />
					<SecureIcon className="benifits-icons" />
					<MadeInIndiaIcon className="benifits-icons" />
					<WithdrawalIcon className="benifits-icons" />
					<CustomerSupportIcon className="benifits-icons" />
				</div>
			</div>
		</div>
	);
};

export default BenefitsIcon;

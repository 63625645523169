import { DOWNLOAD_IOS_URL, DOWNLOAD_URL, isOS } from './constants/constants';

export const handleDownload = async (isIOSLink = isOS) => {
	const urlParams = new URLSearchParams(window.location.search);
	const utmParams = urlParams.toString() || '';

	const deviceData = await getDeviceInfo();

	const location = await getLocation();

	const payload = {
		device_model: deviceData.device_model,
		os_version: normalizeOsVersion(deviceData.os_version),
		carrier_name: deviceData.carrier_name,
		ip_address: deviceData.ip_address,
		time: new Date().toISOString(),
		app_version: '2.10',
		utm_params: utmParams,
		location: location
	};

	try {
		await fetch('https://api.mmp.9span.in/tracker/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(payload)
		});
	} catch (error) {
		console.error('API call failed:', error);
	}

	const link = document.createElement('a');
	link.target = '_blank';
	if (isIOSLink) {
		link.href = DOWNLOAD_IOS_URL;
	} else {
		link.href = DOWNLOAD_URL;
		link.type = 'application/vnd.android.package-archive';
	}
	document.body.appendChild(link);
	link.click();

	// window.gtag('event', 'send', { event_category: 'Download', event_action: 'Click', event_label: 'Download app', event_value: '0' });
	window.dataLayer.push({
		event_category: 'Download',
		event_action: 'Click',
		event_label: isIOSLink ? 'iOS app' : 'Android App',
		event_value: '0'
	});
};

const getDeviceInfo = async () => {
	const info = await (navigator as any).userAgentData.getHighEntropyValues([
		'architecture',
		'bitness',
		'formFactor',
		'wow64',
		'model',
		'platform',
		'platformVersion',
		'fullVersionList'
	]);

	const device_model = info.model;
	const os_version = info.platformVersion;

	// const carrier = (navigator as any).connection ? (navigator as any).connection.effectiveType : 'unknown';
	const ipAddress = await fetch('https://api.ipify.org?format=json')
		.then((response) => response.json())
		.then((data) => data.ip)
		.catch(() => 'unknown');

	return {
		device_model: device_model,
		os_version: os_version,
		carrier_name: '',
		ip_address: ipAddress
	};
};

const getLocation = () => {
	return new Promise((resolve) => {
		if (!navigator.geolocation) {
			console.warn('Geolocation is not supported by this browser.');
			return resolve(null);
		}

		if (location.protocol !== 'https:') {
			console.warn('Geolocation is only available over HTTPS.');
			return resolve(null);
		}

		navigator.geolocation.getCurrentPosition(
			(position) => {
				resolve([position.coords.latitude, position.coords.longitude]);
			},
			(error) => {
				console.error('Geolocation error:', error);
				resolve(null);
			}
		);
	});
};

const normalizeOsVersion = (version: string) => {
	// Split the version string into parts
	const parts = version.split('.');

	// Extract major, minor, and patch versions
	const major = parts[0] || '0';
	const minor = parts[1] || '0';
	const patch = parts[2] || '0';

	// Return the normalized version
	return `${major}.${minor}.${patch}`;
};

interface IProps {
	title: string;
	info: string;
}

const AboutGame: React.FC<IProps> = (props) => {
	return (
		<div className="games-page-step-card-start games-page-step-card bg-color-light-black border-radius-30 about-game ">
			<div className="games-page-step-card--content">
				<h1 className="no-margin font-style--normal font-weight--700 font-size-30 line-height-32 step-card--title">{props.title}</h1>
				<p className="grey-color font-weight--500 font-size-18">{props.info}</p>
			</div>
		</div>
	);
}; 
export default AboutGame;
